<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
              <path d="M9.1523438 2.0019531C8.7590156 1.9770625 8.3695781 2.1798281 8.1738281 2.5488281L6.890625 4.96875L4.1933594 5.4414062C3.6443594 5.5374063 3.2716094 6.0515156 3.3496094 6.6035156L3.7324219 9.3144531L1.828125 11.28125C1.440125 11.68225 1.440125 12.31775 1.828125 12.71875L3.7324219 14.6875L3.3496094 17.398438C3.2716094 17.950437 3.6443594 18.463547 4.1933594 18.560547L6.890625 19.033203L8.1738281 21.451172C8.4348281 21.943172 9.0400156 22.141484 9.5410156 21.896484L12 20.693359L14.460938 21.894531C14.961937 22.139531 15.564172 21.943172 15.826172 21.451172L17.109375 19.033203L19.806641 18.560547C20.355641 18.464547 20.730344 17.948484 20.652344 17.396484L20.269531 14.685547L22.173828 12.71875C22.558828 12.31875 22.558875 11.68225 22.171875 11.28125L20.267578 9.3125L20.650391 6.6015625C20.728391 6.0495625 20.353687 5.5364531 19.804688 5.4394531L17.107422 4.9667969L15.824219 2.5488281C15.564219 2.0578281 14.960937 1.8604688 14.460938 2.1054688L12 3.3066406L9.5390625 2.1054688C9.4140625 2.0442188 9.2834531 2.01025 9.1523438 2.0019531 z M 9 7C10.105 7 11 7.895 11 9C11 10.105 10.105 11 9 11C7.895 11 7 10.105 7 9C7 7.895 7.895 7 9 7 z M 14.400391 7.5507812L15.599609 8.4492188L9.5996094 16.449219L8.4003906 15.550781L14.400391 7.5507812 z M 9 8 A 1 1 0 0 0 8 9 A 1 1 0 0 0 9 10 A 1 1 0 0 0 10 9 A 1 1 0 0 0 9 8 z M 15 13C16.105 13 17 13.895 17 15C17 16.105 16.105 17 15 17C13.895 17 13 16.105 13 15C13 13.895 13.895 13 15 13 z M 15 14 A 1 1 0 0 0 14 15 A 1 1 0 0 0 15 16 A 1 1 0 0 0 16 15 A 1 1 0 0 0 15 14 z"/>
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Discount/Promotion</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="headerName"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="drafts" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="testId" md-label="TEST_ID">{{ item.testId }}</md-table-cell>
            <md-table-cell md-sort-by="headerName" md-label="HEADER NAME">{{ item.headerName }}</md-table-cell>
            <md-table-cell md-sort-by="date" md-label="DATE">{{ item.date }}</md-table-cell>
            <md-table-cell md-sort-by="testType" md-label="TEST TYPE">{{ item.testType }}</md-table-cell>
            <md-table-cell md-sort-by="assignTo" md-label="ASSIGN TO" class="text-victoria">{{ item.assignTo }}</md-table-cell>
            <md-table-cell md-sort-by="lastModified" md-label="LAST MODIFIED">{{ item.lastModified }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onShowDetails(item)" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :title="title" :content="content" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { DetailsModal,  PromptEdit } from "@/components/molecule";
import DetailsDialog from "@/components/molecule/task/DetailsDialog";
import cms from "@/data/cms";
import { drafts } from '@/data/master/qatm/test-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        DetailsModal,
        DetailsDialog,
  },
  data() {
      return {
          currentIndex: 0,
          component: 'CreateModal',
          types: cms.types,
          keyword: '',
          title: "",
          content: "",
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    drafts() {
      if(this.pagination) {
        return drafts.slice(this.paginate.start, this.paginate.end);
      }
      return drafts;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return drafts.filter(item => this.toLower(item.headerName).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails(item) {
      this.component = 'DetailsDialog';
      this.title = 'Details',
      this.content = item;
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>